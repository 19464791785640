<template>
  <div class="login">
    <top title="惠医保登录页" />
    <BackgroundBox
      :box_title="'登录'"
      btn="登录"
      :nullFlag="nullFlag"
      @Onsubmit="Onsubmit"
    >
      <template v-slot:headers>
        <UserNameField v-model="verobj.username" @Verified="Verified" />
        <UserPhoneField v-model="verobj.phone" @Verified="Verified" />
        <PasswordField v-model="verobj.password" />
        <div class="new_type">
          <div @click="onClick('pwd')">忘记密码？</div>
          <div class="go_register" @click="onClick('register')">
            新户领取<van-icon name="arrow" icon-size="10px" />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="radio">
          <!-- <p> 您已阅读并同意<span @click="serve">《服务权益》</span>，<span @click="privacy">《隐私政策》</span>供商业保险理赔使用</p> -->
          <input
            type="radio"
            v-model="istrue"
            @click="istrue = !istrue"
            value="true"
            class="radio_sty"
          />
          <p>
            您已经认真阅读、理解并同意<span @click="serve">《服务权益》</span>、<span
              @click="privacy"
              >《隐私协议》</span
            >
          </p>
        </div>
      </template>
    </BackgroundBox>
  </div>
</template>

<script>
import BackgroundBox from "./components/box";
import UserNameField from "./components/username";
import UserPhoneField from "./components/userphone";
import PasswordField from "./components/password";
import { oauth, logininfo } from "@/api/_api/register";
export default {
  components: { BackgroundBox, UserNameField, UserPhoneField, PasswordField },
  data() {
    return {
      verobj: {
        username: "",
        phone: "",
        password: "",
      },
      istrue: false,
    };
  },
  methods: {
    //输入姓名手机校验是否为
    Verified() {
      let { username, phone } = this.verobj;
      // let str = `${username}-${phone}`
      let params = `name=${encodeURIComponent(username)}&phone=${phone}`;
      if (username && phone) {
        logininfo(params).then((res) => {
          if (res.data.name == null && res.data.phone == null) {
            this.$toast({
              message: "首次登陆,请先点击\n【新户领取】完善资料",
              duration:5000
            });
          }
        });
      }
    },
    //新户领取跳转密码
    onClick(type) {
      sessionStorage.setItem("mess", JSON.stringify(this.verobj));
      this.$router.push({ path: "/password", query: { type } });
    },
    //跳转服务条款
    serve() {
      sessionStorage.setItem("mess", JSON.stringify(this.verobj));
      this.$router.push({ path: "/serve" });
    },
    //跳转隐私协议
    privacy() {
      sessionStorage.setItem("mess", JSON.stringify(this.verobj));
      this.$router.push({ path: "/privacy", query: { show: true } });
    },
    //提交接口
    Onsubmit() {
      this.$toast.loading({
        message: "登陆中...",
        forbidClick: true,
      });
      let { username, phone, password } = this.verobj;
      let str = `${username}-${phone}`;
      let url = `/auth/oauth/token?username=${encodeURIComponent(
        str
      )}&password=${password}&client_id=mobile&client_secret=123456&grant_type=password&scope=server`;
      oauth(url).then((res) => {
        if (res.code == 200) {
          this.$toast.clear();
          this.$store.commit("USER_DATA", res.data);
          localStorage.setItem("user_data", JSON.stringify(res.data));
          this.$cookies.set("token", res.data.token);
          this.$router.push({ path: "/home" });
        }
      });
    },
  },
  mounted() {
    document.title = "惠医保用户端";
    this.verobj = JSON.parse(sessionStorage.getItem("mess")) || { username: "", phone: "", password: "" }
  },
  //四个校验通过既可以点击
  computed: {
    nullFlag() {
      let Regphone = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (
        this.verobj.username &&
        Regphone.test(this.verobj.phone.trim()) &&
        this.verobj.password &&
        this.istrue
      )
        return false;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.verify .field .radio input {
  width: vw(40);
  height: vw(40);
}
.top_height {
  height: vw(92);
  background: #019cf4;
}
</style>

<template>
    <div>
        <div class="name">
          <input
            type="password"
            name="password"
            placeholder="请输入6-12位密码"
            @input="updateValue($event.target.value)"
          />
        </div>
        <p class="reg" :style="!realname?'height:14px':''" >{{realname?'*请输入长度6-12且不带有‘-’字符的密码':''}}</p>
    </div>
</template>

<script>
export default {
    props:{
        objName:{
            type:String,
            default:()=>''
        },
     },
    data(){
        return{
            realname:false,
            userName:''
        }
    },
    methods:{
        updateValue(val){
            if (/-/g.test(val) || (val.length < 6 || val.length > 12)) {
                this.realname = true;
                this.$emit('input','')
            } else {
                this.realname = false;
                this.$emit('input', val.trim())
            }
        }
    }
}
</script>

<style>

</style>

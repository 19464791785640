<template>
  <div>
    <div class="name">
      <input
        v-if="type"
        type="number"
        length="11"
        :disabled="disabled"
        :placeholder="phText"
        @input="updateValue($event.target.value)"
        onkeypress="return !(/[^\d]/.test(String.fromCharCode(event.keyCode)))"
        ref="phone"
        :value="objName"
        @blur="Verified"
      />
      <input type="text" disabled="true" :value="inText" v-else />
    </div>
    <p class="reg" :style="!realphone ? 'height:12px' : ''">
      {{ realphone ? "*请输入正确的手机号" : "" }}
    </p>
  </div>
</template>

<script>
export default {
  model: {
    prop: "objName",
    event: "input",
  },
  props: {
    type: {
      type: Boolean,
      default: () => true,
    },
    inText: {
      type: String,
      default: () => "",
    },
    objName: {
      type: String,
      default: () => "",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    phText: {
      type: String,
      default: () => "请输入手机号码",
    },
  },
  data() {
    return {
      realphone: false,
      userName: "",
      // mess:JSON.parse(sessionStorage.getItem('mess')),
    };
  },
  methods: {
    Verified() {},
    updateValue(val) {
      let Regphone = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!Regphone.test(val.trim())) {
        this.realphone = true;
        this.$emit("input", val.trim());
      } else {
        this.realphone = false;
        this.$emit("input", val.trim());
        this.$emit("Verified");
      }
    },
  },
  mounted() {
    // this.$refs.phone.value=this.mess.phone
  },
};
</script>

<style></style>

import { _axiosapi } from '@/api/_axios';
export const register = (params) => {
    return _axiosapi({
        url: `/member/login/registered`,
        method: "POST",
        data: { request: params },
    })
}
//验证码
export const sendCode = (params) => {
    return _axiosapi({
        url: `/system/common/send/code`,
        method: "POST",
        data: { request: params },
    })
}

//检验是否需要完善身份证信息
export const checkInfo = (params) => {
    return _axiosapi({
        url: '/member/login/check/info',
        method: "POST",
        data: { request: params }
    })
}

//输入姓名、手机号码验证是否为新户
export const logininfo = (params) => {
    return _axiosapi({
        url: `/member/login/member/info?${params}`,
        method: 'GET'
    })
}

//验证是否通过
export const oauth = (url) => {
    return _axiosapi({
        url: url,
        method: "POST",
    })
}
//忘记密码
export const forgotPwd = (params) => {
    return _axiosapi({
        url: '/member/login/check/forgot/password',
        method: "POST",
        data: { request: params }
    })
}
//设置密码
export const setPassword = (params) => {
    return _axiosapi({
        url: '/member/login/forgot/password',
        method: 'POST',
        data: { request: params }
    })
}
//修改手机号
export const updatePhone = (params) => {
    return _axiosapi({
        url: '/member/mine/update/phone',
        method: 'POST',
        data: { request: params }
    })
}
//校验旧手机号验证码
export const oldPhone = (params) => {
    return _axiosapi({
        url: '/member/mine/check/old/phone',
        method: 'POST',
        data: { request: params }
    })
}
//校验填写的个人信息是否正确
export const checkCard = (params) => {
    return _axiosapi({
        url: '/member/login/check/card',
        method: 'POST',

        data: { request: params }
    })
}
<template>
  <div>
    <header>
      <div class="title">
        <div class="logo">
          <img src="@/assets/img/logo@3x.png" alt="" />
        </div>
        <p>惠医保直付药房</p>
      </div>
    </header>
    <div class="verify netop">
      <div class="field">
        <div class="register_text_s">{{ box_title }}</div>
        <template>
          <slot name="headers"></slot>
          <van-button type="info" :disabled="nullFlag" @click="Onsubmit" style="color: #fff" >{{btn}}</van-button>
          <slot name="footer"></slot>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    box_title: {
      type: String,
      default: () => "",
    },
    nullFlag:{
      type:Boolean,
      default:()=>false
    },
    btn:{
      type:String,
      default:()=>''
    }
  },
  methods:{
    Onsubmit(){
      this.$emit('Onsubmit')
    }
  }
};
</script>

<style  lang="scss">
.new_type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: vw(24);
  margin: 0 vw(50) vw(24);
  color: #a2a2a2;
}
.go_register {
  color: #019cf4;
  display: flex;
  align-items: center;
}
header {
  width: 100%;
  height: vw(480);
  background-color: $indexcolor;
  position: relative;
  .title {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    .logo {
      width: vw(120);
      height: vw(120);
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    p {
      margin-top: vw(40);
      color: white;
      font-size: vw(40);
    }
  }
}
.verify {
  width: vw(654);
  background: #ffffff;
  box-shadow: 0px 0px 10px 2px rgba(133, 133, 133, 0.2);
  border-radius: vw(8);
  position: absolute;
  top: vw(434);
  left: 0;
  margin: 0 vw(48);
  .field {
    width: 100%;
    position: relative;
    margin: vw(48) 0;
  }
}
.register_text_s {
  font-size: vw(48);
  margin: 0 vw(48) vw(48);
  color: #484848;
}
.netop {
  top: vw(460) !important;
}
.verify {
  width: vw(654);
  background: #ffffff;
  box-shadow: 0px 0px 10px 2px rgba(133, 133, 133, 0.2);
  border-radius: vw(8);
  position: absolute;
  top: vw(434);
  left: 0;
  margin: 0 vw(48);
  .field {
    width: 100%;
    position: relative;
    margin: vw(48) 0;
    .name {
      width: vw(558);
      height: vw(82);
      display: flex;
      background-color: #f9f9f9;
      margin: 0 vw(48);
      border-radius: vw(40);
      span {
        align-self: center;
        font-size: vw(28);
        font-weight: 400;
        color: #019cf4;
        padding-right: vw(36);
        flex-shrink: 0;
      }
      .icon {
        width: vw(40);
        height: vw(40);
        align-self: center;
        margin-left: vw(30);
        img {
          width: vw(40);
          height: vw(40);
        }
      }
      input {
        border: none;
        outline-style: none;
        font-size: vw(28);
        background-color: #f9f9f9;
        border-radius: vw(50);
        width: 100%;
        padding: vw(20) vw(48);
      }
      input::-webkit-input-placeholder,
      textarea::-webkit-input-placeholder {
        color: #a1a1a1;
        font-size: vw(26);
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }
      .number {
        color: #b0b0b0;
        align-self: center;
        font-size: vw(30);
        flex-shrink: 0;
        padding-right: vw(48);
      }
    }
    .radio {
      margin: vw(20) vw(48);
      display: flex;
      align-items: center;
      p {
        margin-left: vw(8);
        font-size: vw(24);
        width: vw(592);
        color: #999;

        span {
          color: #019cf4;
        }
      }
      input {
        width: vw(26);
        height: vw(26);
      }
    }
    .van-button {
      width: vw(558);
      height: vw(82);
      font-size: vw(34);
      border: none;
      outline-style: none;
      border-radius: vw(50);
      font-weight: 400;
      margin-top: vw(25);
      margin: vw(25) vw(48) 0;
    }
  }
}
.reg {
  color: red;
  font-size: vw(24);
  width: 80%;
  margin-left: vw(90);
  height: vw(40);
  display: inline-block;
}
</style>